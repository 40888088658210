import { useForm } from 'react-hook-form';
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import Cookies from 'universal-cookie';
import ObtieneDatosCookies from '../tools/obtieneDatosCookiesUsuario.js';
import devuelveCategorias from '../modulos/devuelveCategorias.js';
import { useState } from 'react';
import swal from 'sweetalert'
import convierteLtb from './convierteCadeteReincorporar.js'
import convierteSoloEstado from './convierteSoloEstado.js'


function ModalREincorporaYa (props) {
  const cookies=new Cookies();
  const navitate = useNavigate();
  const ID=cookies.get('id');
  const [modalCadeteIsOpen, setCadeteIsOpen] = useState(false);
  const customStyles = {
    content: {/*
      top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width:"auto",
    height:"auto"
 */
    },
  };

  function openModalCadete() {
    setCadeteIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    
  }
  function closeModalCadete() {
    setCadeteIsOpen(false);
 
  }
  var cierro=false;
  const fecha = new Date();
const hoy = "Fecha: "+fecha.toLocaleDateString();

  const URI='https://wwwa.isepsantafe.edu.ar/expedientes/'
  
const parametro=props.datosCadete
const usuario=ObtieneDatosCookies()
const datosCurso=props.Datos

  const store=async (data)=>{
   
    const client = axios.create({
      baseURL: process.env.REACT_APP_BASEURL+"cadetes/"
      });
   const respuesta=await client.post('/creaReincorporacion/',convierteLtb(data))
   if(respuesta){
    modificaEstado(parametro,"REGULAR")
    queHago(respuesta.data).then(cierro?()=>props.myID("dale"):"")
   }
   
   
   
   
  
}

  const queHago=async(respuesta)=>{

    if(respuesta.message==="Registro creado o actualizado correctamente!!"){
      swal("Exito en la Reincorporacion en esta Promoción de "+parametro.cadeteDni);
      props.myID("dale")
    }
    else{
      swal("Algo Fallo!, consulte con el area Tecnica error A009!!!")
     
    
       }}

const modificaEstado=async(cadeteDni,estado)=>{


          const client = axios.create({
           
              baseURL:process.env.REACT_APP_BASEURL+"cadetes/"
              });
              try {
        
              
        
                await client.put('actualiza/'+cadeteDni.cadeteDni,convierteSoloEstado(parametro,estado))
                }
              catch (error) {}
         
        }
  const {register, formState: { errors }, handleSubmit,} = useForm({
  defaultValues: parametro//recupero lo que pasa por parametros
  });
  

const categorias=devuelveCategorias()

  const procesandoLtb = (datoLtb) => {

    store(datoLtb)

 
  }

//Aca enpieza el react lo anterior es javascript y hooks


  return (
    <>
    <div  >
   
      <form  onSubmit={handleSubmit(procesandoLtb)}>
       

<div class="row">
<div class='visually-hidden' >

          <div class="form-floating mb-3">
                  <input value ={parametro.cadeteDni} type="text" class="form-control" id="floatingInput" placeholder="cadeteDni" 
         {...register('cadeteDni', {
       
          })}/>
        <label for="floatingInput">DNI Cadete</label>
    </div>
      <div class="form-floating mb-3" >
      <input value ={usuario.id}type="text" class="form-control" id="floatingNombres" placeholder="idUsuario" 
      {...register('idUsuario', {
      
      })}/>
      <label for="floatingNombres">Identificacion Usuario</label>
    </div>
    <div class="form-floating mb-3">
      <input value={usuario.sedeID}type="number" class="form-control" id="floatingUsuario" placeholder="Sede Usuario" 
      {...register('idSede', {
      
      })}/>
      <label for="floatingUsuario">Sede ID</label>
    </div>
  
</div>

<div class="row">

          <div class='input-group-text' >
          <div class="form-floating mb-3">
  <select class="form-select" id="floatingCadeteGenero" aria-label="CadeteGenero"
  {...register('motivo', {
    required: true
  })}
  >
   
    <option class="mb-3" value="Perdon Inasistencias">Perdon Inasistencias</option>
    <option class="mb-3" value="Baja Voluntaria">Baja Voluntaria</option>
    <option class="mb-3" value="Lic. Medica">Lic. Medica</option>
    <option class="mb-3" value="Otro">Otro</option>
    
    
  </select>
  <label for="form-floating mb-3">Motivo de la Reincorporacion</label>
</div>
<div class="form-floating mb-3">
  <input value={parametro.promocion} class="form-control" placeholder="CURSO" id="floatingCurso" 
   {...register('promocionAlta', {
    

  })}/>
  <label for="floatingCurso">Promoción</label>
</div>
     
<div>
  
</div>
</div>

<div class='card-header mb-3' >
<div class='input-group-text' >
<div class="form-floating mb-3">
  <input  class="form-control" placeholder="Nro Resolucion" id="floatingCurso" 
   {...register('resolucionAlta', {
    required: true,

  })}/>
  <label for="floatingCurso">Nro Resolucion</label>
</div>
<div class="form-floating mb-3">
  <input type='date' class="form-control" placeholder="CURSO" id="floatingCurso" 
   {...register('fechaAlta', {
    required: true,

  })}/>
  <label for="floatingCurso">Fecha</label>
</div>
</div>
<div class="form-floating">
  <textarea class="form-control" style={{height:"250px",resize:"none"}} placeholder="Descripcion de la solicitud" id="floatingDescripcion" 
   {...register('descripcion', {
    required: true,
    maxLength:1000,
 
  })}></textarea>
  <label for="floatingDescripcion">Descripcion</label>
</div>    
<input type="submit" value="Reincorporar Ya"  className="btn btn-success" />
<input type="button" value="Cancelar"  className="btn btn-danger" onClick={()=>props.myID("dale")}/>
</div>


</div>

</div>
 </form>

   
        
        </div>
 
        </>
  )}

export default ModalREincorporaYa;
