import React , { useState, useRef, useEffect }from "react";
import {useNavigate,useParams, useSubmit} from 'react-router-dom';
import obtieneDatos  from '../tools/obtieneDatosCookiesUsuario'
import devuelveDependencia from '../tools/devuelveDependencia'
import './menu.css'
import { Spinner } from 'reactstrap';
import axios from 'axios';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import FormEditaDocente from "./formEditaDocente";
import DevuelveMaterias from './DevuelveMaterias'
import FormEditaMateria from "./formEditaMaterias";
import FormEditaCurso from "./formEditaCurso";
import DevuelveDocentes from "./DevuelveDocentes";
import { CSVLink } from "react-csv";
import ModuloDevuelveInasistenciasDocentes from "./DevuelveInasistenciasDocentes";
import { ordeno } from "../tools/ordenaArrayDeObjetos";
import { addDays } from 'date-fns';
import devuelveSedeConsultada from '../tools/devuelveSede';
import CargaMasiva from "./CargaMasivaLector";
import regresaBienFechaYDia from "../tools/regresaBienFechayDia";

function Docentes () {
  const sedes=devuelveSedeConsultada()
  let fecha=new Date()
  const Usuario=obtieneDatos();
  if(!Usuario.id){
    alert("Acceso no Autorizado")
     window.location.href='./';
  }
  const printRef = useRef();

  const horasMaximasDocenteCivil=36;
  const horasMaximasDocenteRetirado=10;
  const horasMaximasDocenteEnActividad=12;
  const [spinnerModal,setSpinnerModal]=useState(false)
  const [sedeLector,setSedeLector]=useState("Rosario")
  const [modalHorasIsOpen, setHorasIsOpen] = useState(false);
  const [veoCargaLector,setVeoCargaLector]=useState(false)
  const[NominaFull,setNominaFull]=useState([])
  const [modalControlLectorIsOpen,setControlLectorIsOpen]=useState(false)
    const [modalDocentesIsOpen, setDocentesIsOpen] = useState(false);
    const[escuelaSeleccionadaFiltro,setEscuelaSeleccionadaFiltro]=useState([])
    const [modalInformeDocenteIsOpen, setmodalInformeDocenteIsOpen] = useState(false);
    const[mostrarEditaMateria,setEditaMateria]=useState(false);
    const[mostrarEditaCurso,setEditaCurso]=useState(false);
    const [cuerpoDocente,setCuerpoDocente]=useState({})
    const [cargando, setCargando] = useState(false);
    const[ docenteSeleccionado,setDocenteSeleccionado]=useState(false) 
    const[ crearDocente,setCrearDocente]=useState(false)  
    const[muestraDocente,setMuestaDocente]=useState(false) 
    const [escuelaSeleccionada, setEscuelaSeleccionada] = useState(false);
    const [materiasDevueltas, setMateriasDevueltas] = useState(false);
    const [infoMateriasDevueltas, setInfoMateriasDevueltas] = useState(false);
    const [mostrarMateriasDevueltas, setMostrarMateriasDevueltas] = useState(false);
    const[cursosSeteados, setCursosSeteados]=useState(false)
    const[mostrarCursos, setMostrarCursos]=useState(false)
    const[nominaDocente,setNominaDocente]=useState([])
    const[ausenciasDocentes,setAusenciasDocentes]=useState(null)
const[modalInformeInasistenciasDocenteIsOpen,setModalInformeInasistenciasDocenteIsOpen]=useState(false)
  

  
const CursosDisponibles=async(escuela)=>{
 
  const client = axios.create({
    
    baseURL:process.env.REACT_APP_BASEURL+"cursos/devuelveCursosPorEscuela/"
    });
    try {
     const docente= await client.post('/'+escuela,{"estadoCurso":"Activo"})
     if(docente.data){

return docente.data
     }
     
      }
    catch (error) {}
}


  const navitate = useNavigate()

  function openModalDocentes() {
    setDocentesIsOpen(true);
  }
  const mapeaLasHoras = (dato) => {
    var horasdeesteDocente=[]
    if (Array.isArray(dato) && dato.every(item => typeof item === 'object' && item !== null)) {
      dato.map((valor) => 
      horasdeesteDocente.push()
        );
    } else {
      console.warn("El dato proporcionado no es un array de objetos.");
    }
  };
  
  function closeModalDocentes() {
 
    setDocentesIsOpen(false);
    setDocenteSeleccionado(null)
   

  }
  const[DatosMaterias,setDatosMaterias]=useState(null)
  const devuelveAllMaterias=async()=>{
    const client = axios.create({
  
      baseURL:process.env.REACT_APP_BASEURL+"materias/devuelveAllMaterias"
      });
      try {
       
        const datos=await client.post('/')
        if(datos){
          setDatosMaterias(datos.data)
          return datos.data
        }
      }
      
      catch{}
      }

  function openModalHoras() {
    setHorasIsOpen(true);
  }

  
  function closeModalEditaMateria() {
    setEditaMateria(false);
  
  }
  function closeModalInformeDocentes() {
    setmodalInformeDocenteIsOpen(false);
  
  }
  const openModalInformeDocente=async()=> {
    setSpinnerModal(true)
    const client = axios.create({

      baseURL:process.env.REACT_APP_BASEURL+"docentes/devuelveDocentes"
      });
      try {
       const docente= await client.post('/')
       if(docente){
setCuerpoDocente(docente.data)


       const devuelveHoras=(index)=>{

  
          if(docente.data.Horas[index].length>0){
            var HorasTotales=0
            var Horas=[]
            for (var i = 0; i < docente.data.Horas[index].length; i++) {
           Horas.push(docente.data.Horas[index][i])
            HorasTotales+=docente.data.Horas[index][i].horasAsignadas
          } 
    const variable=[HorasTotales,Horas]
            return(variable)
           
          }else{
            return "Sin horas"
          }
        
        }
        var docentes=[]
       
     
         for (let index = 0; index < docente.data.Docente.length; index++) {
         const docentex=docente.data.Docente[index]
         const cc=devuelveHoras(index)
          docentex.HorasCatedrasTotales=cc[0]
          docentex.HorasQueTiene=cc[1]
         
          

         docentes.push(docentex)
        
         }
     ordeno(docentes,"docenteApellido")
        setNominaDocente(docentes)
       
        setmodalInformeDocenteIsOpen(true)
        setSpinnerModal(false)
         
 }
         else{
          setSpinnerModal(false)
         } 
        }
      catch (error) {}
 
  }

  
  function closeModalHoras() {
    setHorasIsOpen(false);
  
  }

   

const ExisteDocente=async(info)=>{
  setDocenteSeleccionado(null)
  const client = axios.create({

      baseURL:process.env.REACT_APP_BASEURL+"docentes/devuelveDocente"
      });
      try {
       const docente= await client.post('/'+info)
       if( docente.data.docente[0]){
        console.log(docente.data)
        await setDocenteSeleccionado(docente.data).then(setMuestaDocente(true))
      
       }else{
       
        seteadoDocente(null)
        setMuestaDocente(false)
        setCrearDocente(true)
       }
        }
      catch (error) {}
 
}
const seteadoDocente=async(docente)=>{

}

const {register, formState: { errors }, handleSubmit,} = useForm({
  defaultValues:{}
  });





const headerInasistencias = [
  { label: "Nro Control", key: "id" },
  { label: "Docente", key: "docente" },
  { label: "DNI", key: "docenteDni" },
  { label: "Motivo", key: "motivo" },
  { label: "Escuela", key: "escuela" },
  { label: "Curso", key: "curso" },
  { label: "Materia", key: "materia" },
  { label: "Sede Cursada", key: "sedeCursada" },
  { label: "Division", key: "division" },
  { label: "Fecha de Inasistencia", key: "fechaInasistencia" }
 


 
 
];

const volver=()=>{
    navitate('/usuarioLogueado')
}

const consultaDocente = (data) => {

 ExisteDocente(data.docenteDni)
}
const seteaEscuela=async(dato)=>{
  setEditaCurso(false)
setEscuelaSeleccionada(dato)
muestroEdita("")

  const client = axios.create({

    baseURL:process.env.REACT_APP_BASEURL+"materias/devuelveMateriaPorEscuela"
    });
    try {
     
      const Materias=await client.post('/'+dato,{"estadoMateria":"Activo"})
      const cursosDisponibles=await CursosDisponibles(dato)
      if(cursosDisponibles!=null){
         
        setCursosSeteados(cursosDisponibles)
      
        setMostrarCursos(true)
      }
      if(Materias.data.Materia.length>0){
    
       
       setMateriasDevueltas(Materias.data)
      
        setMostrarMateriasDevueltas(true)
       
       
       }else{
       
        setMateriasDevueltas(null)
        setMostrarMateriasDevueltas(false)
       }
      }
    catch (error) {}

}



const verificoMateria=async(dato)=>{
 
  const client = axios.create({

    baseURL:process.env.REACT_APP_BASEURL+"materias/devuelveMateria"
    });
    try {
     
      const Materias=await client.post('/'+dato.id,)
      if(Materias.data.Materia.length>0){
    
       
        setInfoMateriasDevueltas(Materias.data.HorasAsignadas)
      
        const valor = window.prompt("Ingrese el nro de DNI del docente para asignar horas de una division de "+Materias.data.Materia[0].nombreMateria)
        if(valor!=null){
          consultarDocenteAsignaMateria(valor,Materias)
        }
       
       }else{
       
        setMateriasDevueltas(null)
        setMostrarMateriasDevueltas(false)
        
       }
      }
    catch (error) {}
    
}

const consultarDocenteAsignaMateria=async(dniDocente,materia)=>{
  const infoConsulta={
    "docenteDni":dniDocente,
    "fechaMaximaInicio":materia.data.Materia[0].fechaInicio,
    "fechaMaximaFinalizacion":materia.data.Materia[0].fechaFinalizacion
  }
  console.log(infoConsulta)
  const client = axios.create({
 
    baseURL:process.env.REACT_APP_BASEURL+"docentes/puedeDarHoras"
    });
    try {
     
      const resultadoConsulta=await client.post('/',infoConsulta)

if(resultadoConsulta.data.docente==""){

const entonces=window.confirm("Docente Inexistente en el sistema, gestionelo!!!!!")
if(entonces){
  closeModalHoras()
  setDocentesIsOpen(true)

  }
}
else{
  var horasMaximas=0;
  if(resultadoConsulta.data.docente[0].docenteTipo=="Policia Actividad"){
    horasMaximas=horasMaximasDocenteEnActividad
  }else if(resultadoConsulta.data.docente[0].docenteTipo=="Policia Retirado"){
    horasMaximas=horasMaximasDocenteRetirado
  }else{
    horasMaximas=horasMaximasDocenteCivil
  }
  if(resultadoConsulta.data.horas<horasMaximas&&resultadoConsulta.data.docente[0].docenteEstado=="Activo")
  {
    console.log(resultadoConsulta)
    const horasDisponibles=horasMaximas-resultadoConsulta.data.horas
    console.log(horasDisponibles)
   if(horasDisponibles>=materia.data.Materia[0].bloqueHorario){
   asignoMateria(resultadoConsulta.data,materia.data)
   }else{
    alert("Docente Excedido de horas para esta materia")
   }
  
   
   }else{
   
  
    alert("Docente con la cantidad de horas maximas para ese periodo o Inactivo Actualmente")
   }
}

     
      }
    catch (error) {}




}
const asignoMateria=async(info,materia)=>{
  

const devuelveDivisionesLibres=async(divSolicitada)=>{

  const client = axios.create({
  
    baseURL:process.env.REACT_APP_BASEURL+"materias/devuelveMateriaDivisionesLibres"
    });
    try {
     
      const DivisionesLibres=await client.post('/',divSolicitada)


return DivisionesLibres.data

}
catch (error) {}
}

const divisiones=await devuelveDivisionesLibres(materia)

if(divisiones.Libres.length>0){


  var divisionesLibres=": "

  for (var i = 0; i < divisiones.Libres.length; i++) {
    divisionesLibres+=" "+divisiones.Libres[i]+" - "
  }
  
  const respuesta=window.prompt("se encuentran libres las divisiones "+divisionesLibres+" , que division desea asignar")
if(respuesta!=null){
  if(divisiones.Libres.find(x=>x==respuesta)){
  alert("asignando "+respuesta)
 
  const asignacionADocente={
    idMateria:materia.Materia[0].id,
    division:respuesta,
    docenteDni:info.docente[0].docenteDni,
    horasAsignadas:materia.Materia[0].bloqueHorario,
    fechaInicio:materia.Materia[0].fechaInicio,
    fechaFinalizacion:materia.Materia[0].fechaFinalizacion,
    usuarioID:Usuario.id,
    sedeID:Usuario.sedeID,
    dependenciaID:Usuario.destino
  }


  const client = axios.create({

    baseURL:process.env.REACT_APP_BASEURL+"materias/asignaMateria"
    });
    try {
     
      const d=await client.post('/',asignacionADocente)
      if(d){
        seteaEscuela(escuelaSeleccionada)}
}catch{}}




}else{
  alert("No esta libre la division "+respuesta)
}
}else{alert("Se acabaron")}

}

const crearMaterias=()=>{
  if(mostrarMateriasDevueltas){
    setMostrarMateriasDevueltas(false)
    setEditaCurso(false)
  }

  setEditaMateria(!mostrarEditaMateria)
}

const crearCurso=()=>{
  if(mostrarMateriasDevueltas){
    setMostrarMateriasDevueltas(false)
    setEditaMateria(false)
  }

  setEditaCurso(!mostrarEditaCurso)
}


const muestroEdita=(dato)=>{
  setEditaMateria(false)

}
const resetForm =()=>{
  setDocenteSeleccionado(null)
  document.getElementById("consulta-dni-docente").reset();
  setCrearDocente(false)
}
const definoCrear=()=>{
  if(crearDocente){
    setCrearDocente(!crearDocente)
  }else{
    setCrearDocente(!crearDocente)
  }
 
}
const restaDias=(cantidad)=>{
 const fechaOrigen=new Date()

  return addDays(fechaOrigen,-cantidad)
}
const informaInasistencias=async(fecha1,fecha2)=>{

  function sortArrayByAttribute(arr, attribute) {
    return arr.sort((a, b) => {
      if (a[attribute] < b[attribute]) {
        return -1;
      }
      if (a[attribute] > b[attribute]) {
        return 1;
      }
      return 0;
    });
  }


setSpinnerModal(true)

  setModalInformeInasistenciasDocenteIsOpen(false)
    function filtro(inasistenciasPasadas){
      if(Usuario.destino=="1"||Usuario.destino=="7"||Usuario.destino=="45"||Usuario.destino=="10"){
        setAusenciasDocentes(inasistenciasPasadas)
      }
else if(Usuario.destino=="11"){
  setAusenciasDocentes(inasistenciasPasadas.filter(x=>x.escuela=="Educacion a Distancia"))
}
else if(Usuario.destino=="12"||Usuario.destino=="16"||Usuario.destino=="25"){
  setAusenciasDocentes(inasistenciasPasadas.filter(x=>x.escuela=="Escuela de Policia"))
}
else if(Usuario.destino=="13"||Usuario.destino=="17"||Usuario.destino=="26"){
  setAusenciasDocentes(inasistenciasPasadas.filter(x=>x.escuela=="Escuela Superior"))
}
else if(Usuario.destino=="14"||Usuario.destino=="18"||Usuario.destino=="27"){
  setAusenciasDocentes(inasistenciasPasadas.filter(x=>x.escuela=="Escuela de Especialidades"))
}
else if(Usuario.destino=="15"||Usuario.destino=="19"||Usuario.destino=="28"){
  setAusenciasDocentes(inasistenciasPasadas.filter(x=>x.escuela=="Escuela de Investigaciones"))
}
      setModalInformeInasistenciasDocenteIsOpen(true)
      setSpinnerModal(false)
    }
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JS son 0-indexados
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

let fechaHoy=fecha

let hace30Dias = new Date(fecha)
let fechaInicial=hace30Dias.setDate(fecha.getDate() - 15);
let fechaa=new Date(fechaInicial)
const manana = addDays(fechaHoy, 1);

if(fecha1 && fecha2){//si me consulta fechas devuelvo, sino por defecto los ultimos 30 dias
  try {
    const client = axios.create({

      baseURL:process.env.REACT_APP_BASEURL+"docentes/devuelveInasistencias"
      });

    const e=await client.post('/'+"?fechaInicial="+fecha1+"&fechaFinal="+fecha2)
    if(e){

      sortArrayByAttribute(e.data,"fechaInasistencia")
     filtro(e.data)
     }
}catch{}
}else{
  try {
    const clientw = axios.create({

      baseURL:process.env.REACT_APP_BASEURL+"docentes/devuelveInasistencias"
      });
     
      
    const d=await clientw.post('/'+"?fechaInicial="+formatDate(fechaa)+"&fechaFinal="+manana)
    if(d){

      sortArrayByAttribute(d.data,"fechaInasistencia")

      filtro(d.data)
     }
}catch{}
}
}
  

const customStyles = {
  content: {
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  width:"auto",
  height:"auto"

  },
};
const customStylesDocentes = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '90%',  
    maxHeight: '90%', 
    width: 'auto',
    height: 'auto',
    overflow: 'auto' 

  },
};

const customStylesInasistencias = {

    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '90%',  
      maxHeight: '90%', 
      width: 'auto',
      height: 'auto',
      overflow: 'auto'  
    },
};

const ordenoLasAusencias=async(array,propiedad)=>{
setModalInformeInasistenciasDocenteIsOpen(false)
const nuevo=await ordeno(array,propiedad)
setAusenciasDocentes(nuevo)
setModalInformeInasistenciasDocenteIsOpen(true)
}
const seteaListaInasistenciasFinal=()=>{

}
const headersNominaFull = [
  { label: "Nro Control", key: "Nro Control" },
  { label: "Apellido", key: "Apellido" },
  { label: "Nombres", key: "Nombres" },
  { label: "DNI", key: "DNI" },
  { label: "Materia", key: "Materia" },
  { label: "Escuela", key: "Escuela" },
  { label: "Sede", key: "Sede" },
  { label: "Horas Totales", key: "Horas Totales" }

 
 
];
const creaListaDocenteDirector=async()=>{

  const eliminarDuplicadosPorIdMateria = (array) => {
    // Verifica si el array está vacío
    if (!Array.isArray(array) || array.length === 0) {
      return [];
    }
  
    // Crea un Map para almacenar objetos únicos por idMateria
    const mapaUnicos = new Map();
  
    array.forEach(item => {
      // Solo agrega el primer objeto con un idMateria específico
      if (!mapaUnicos.has(item.idMateria)) {
        mapaUnicos.set(item.idMateria, item);
      }
    });
  
    // Devuelve los valores únicos del mapa como un nuevo array
    return Array.from(mapaUnicos.values());
  };
  
  


  const DatosMaterias=await devuelveAllMaterias()
var DocentesNuevos=[]
nominaDocente.map((esteDocente)=>{
  const arraySinDuplicados = eliminarDuplicadosPorIdMateria(esteDocente.HorasQueTiene);
  arraySinDuplicados.forEach(element => {
  //  console.log(DatosMaterias.find(x=>x.id==element.idMateria).nombreMateria)
  console.log(esteDocente)
    var DocenteCreado={
      "Nro Control":esteDocente.id,
      "Apellido":esteDocente.docenteApellido,
      "Nombres":esteDocente.docenteNombres,
      "DNI":esteDocente.docenteDni,
      "Materia": DatosMaterias.materias.find(x=>x.id==element.idMateria).nombreMateria,
      "Escuela":DatosMaterias.materias.find(x=>x.id==element.idMateria).escuela,
      "Sede":element.sedeCursada,
      "Horas Totales":esteDocente.HorasCatedrasTotales}
    DocentesNuevos.push(DocenteCreado)
 });
  
 //console.log(arraySinDuplicados);



})
  
  
  
  
 



  setNominaFull(DocentesNuevos)
  dale()
}
const dale=()=>{
  alert("Si")
  
}

    return(
        <>
          <Modal
        isOpen={spinnerModal}
       
        onRequestClose={closeModalInformeDocentes}
        style={customStyles}
        ariaHideApp={false}
      >Cargando Docentes...<Spinner>

</Spinner>
      </Modal>
      <Modal
        isOpen={modalControlLectorIsOpen}
       
        onRequestClose={()=>setControlLectorIsOpen(false)}
        style={customStyles}
        ariaHideApp={false}
      >
       <div className=" btn-group"> <button className="btn btn-danger" style={{display:"flex",alignContent:"flex-end"}}onClick={()=>setControlLectorIsOpen(false)}>x </button> <a style={{alignSelf:"center"}}><strong>Control Lector. </strong></a>

      
       
      </div>
       
     
<div style={{overflow:"auto", height:"350px", width:"650px"}}>
{Usuario.destino=="1"||Usuario.destino=="7"?<> <select name="" id="" onChange={(e)=>setSedeLector(e.target.value)}>
{sedes.filter(x=>x.id>1).map((cate) =>
            <><option key={cate.id} value={cate.descripcion}>
            {cate.descripcion}
            </option></>)}
      
      </select></>:""}
      <button onClick={()=>setVeoCargaLector(true)}>Carga</button>
      {veoCargaLector?<CargaMasiva sede={sedeLector}myID={()=>setVeoCargaLector(false)}/>:"No veo"}
</div>
      </Modal>





        <Modal 
        isOpen={modalInformeDocenteIsOpen}
       
        onRequestClose={closeModalInformeDocentes}
        style={customStylesDocentes}
        ariaHideApp={false}
      >
       <div className=" btn-group"> <button className="btn btn-danger" style={{display:"flex",alignContent:"flex-end"}}onClick={closeModalInformeDocentes}>x </button> <a style={{alignSelf:"center"}}><strong>Informe Docente. </strong>Cantidad de Docentes:{" "+nominaDocente?nominaDocente.length:""}</a>



       
      </div>
       
     
<div style={{overflow:"auto", height:"350px", width:"auto"}}>

<DevuelveDocentes Docentes={nominaDocente}/>


  </div>
      </Modal>
 <Modal 
        isOpen={modalInformeDocenteIsOpen}
       
        onRequestClose={closeModalInformeDocentes}
        style={customStyles}
        ariaHideApp={false}
      ><div ref={printRef} style={{ padding: '20px', border: '1px solid black', marginBottom: '20px' }}>
       <div className=" btn-group"> <button className="btn btn-danger" style={{display:"flex",alignContent:"flex-end"}}onClick={closeModalInformeDocentes}>x </button> <a style={{alignSelf:"center"}}><strong>Informe Docente. </strong>Cantidad de Docentes:{" "+nominaDocente?nominaDocente.length:""}</a>
     
       {Usuario.destino==7||Usuario.destino==1?
      <CSVLink data={NominaFull} onClick={creaListaDocenteDirector} filename={regresaBienFechaYDia(new Date().toISOString())+"_Nomina_Docente.csv"} style={{marginTop:"4px",marginLeft:"4px"}}className="btn btn-success"separator={";"}
      target="_blank" headers={headersNominaFull}> Descargar</CSVLink>:""} 

       
      </div>
       
     
<div style={{ overflowY: 'auto', maxHeight: '60vh' }} >


<DevuelveDocentes Docentes={nominaDocente}/>


  </div></div>
      </Modal>







      <Modal
        isOpen={modalInformeInasistenciasDocenteIsOpen}
        onRequestClose={()=>setModalInformeInasistenciasDocenteIsOpen(false)}
        style={customStylesInasistencias}
        ariaHideApp={false}
      >
       <div className=" btn-group"> <button className="btn btn-danger" onClick={()=>setModalInformeInasistenciasDocenteIsOpen(false)}>x </button> <a style={{alignSelf:"center"}}><strong>Informe Inasistencias Docente: </strong></a>

       <CSVLink data={ausenciasDocentes} onClick={()=>seteaListaInasistenciasFinal()} filename={"Informe Inasistencias Docentes"} style={{marginTop:"4px",marginLeft:"4px"}}className="btn btn-success"separator={";"}
      target="_blank" headers={headerInasistencias}> Descargar </CSVLink>

       
      </div>
       
      
<div style={{height:"470px", width:"800px"}}>

{ausenciasDocentes?<>
  <div className="btn-box">
    <h5>Consulta del periodo: POR DEFECTO ULTIMOS 15 DIAS</h5>
    <label>Fecha Inicio<input type="date" className="form-select"   id="fechaInicio" /></label>
    <label htmlFor="">Fecha Final<input className="form-select" type="date" id="fechaFinal" /></label>
    <button className="btn btn-primary" onClick={()=>informaInasistencias(document.getElementById('fechaInicio').value,document.getElementById('fechaFinal').value)}>Consultar</button>
    <button className="btn btn-warning" style={{fontSize:"10px"}} onClick={()=>informaInasistencias(restaDias(7),restaDias(-1))}>Ultimos 7 días</button>
    <button className="btn btn-warning" style={{fontSize:"10px"}} onClick={()=>informaInasistencias(restaDias(15),restaDias(-1))}>Ultimos 15 días</button>
    <button className="btn btn-warning" style={{fontSize:"10px"}} onClick={()=>informaInasistencias(restaDias(30),restaDias(-1))}>Ultimos 30 días</button>
    <button className="btn btn-warning" style={{fontSize:"10px"}} onClick={()=>informaInasistencias(restaDias(90),restaDias(-1))}>Ultimos 3 meses</button>
    <button className="btn btn-warning" style={{fontSize:"10px"}} onClick={()=>informaInasistencias(restaDias(180),restaDias(-1))}>Ultimos 6 meses</button>
    <button className="btn btn-warning" style={{fontSize:"10px"}} onClick={()=>informaInasistencias(restaDias(365),restaDias(-1))}>Ultimo año</button>
    </div><div>


     
  </div>   


<ModuloDevuelveInasistenciasDocentes Docentes={ausenciasDocentes} refresco={()=>informaInasistencias(restaDias(15),restaDias(-1))} myID={(value)=>ordenoLasAusencias(ausenciasDocentes,value)} /></>:<></>}




  </div>
      </Modal>

      <Modal
        isOpen={modalDocentesIsOpen}
        onRequestClose={closeModalDocentes}
        style={customStyles}
        ariaHideApp={false}
      >
       <div className=" btn-group"> <button className="btn btn-danger" style={{display:"flex",alignContent:"flex-end"}}onClick={closeModalDocentes}>x </button> <a style={{alignSelf:"center"}}><strong>Gestion Cuerpo Docente: </strong></a>


       
      </div>
       
      <div style={{ color:"white",textAlign:"center", width:"600px",height:"auto", margin:"5px",display:"flex", justifyContent:"space-evenly", alignItems:"center"}}>
        <form id="consulta-dni-docente" onSubmit={handleSubmit(consultaDocente)}>
           <div class='input-group-text' >
          <div class="form-floating mb-3">
      <input type="number" class="form-control" id="floatingDNI" placeholder="DNI" 
      {...register('docenteDni', {
        required: true,
        minLength: 8
      })}/>
      <label for="floatingDNI">DNI</label>
      
    </div>
  

    <input type="submit" value="Consultar DNI"  className="form-floating mb-3" style={{borderRadius:"15px",backgroundColor:"#888", color:"white"}}/>

         
          </div>
         
      </form>
     
</div>
<div style={{overflow:"auto", height:"350px", width:"650px"}}>


{docenteSeleccionado?<><FormEditaDocente datos={docenteSeleccionado.docente[0]} myID={()=>resetForm("")}/>
<h2>{docenteSeleccionado.horas +" horas asignadas actualmente"}</h2>




</>:""}
{!docenteSeleccionado?
<><div><h4 style={{color:"black", textAlign:"center", alignItems:"center"}}>"No existe docente con ese DNI quiere crearlo?"</h4><button onClick={definoCrear}>Crear</button></div></>:""}
{crearDocente?<><FormEditaDocente datos={""} myID={()=>resetForm("")}/></>:""}
  </div>
      </Modal>

      <Modal
        isOpen={modalHorasIsOpen}
        onRequestClose={closeModalHoras}
        style={customStyles}
        ariaHideApp={true}
      >
       <div className=" btn-group"> <button className="btn btn-danger" style={{display:"flex",overflow:"auto",alignContent:"flex-end"}}onClick={closeModalHoras}>x </button> <a style={{alignSelf:"center"}}><strong>Gestion Horas Catedras: </strong></a>



      </div>
      <div style={{}}>
      <div style={{ height:"450px",width:"800px"}}>
      <div style={{marginInline:"20px",height:"450px",width:"750px" }}>
      <div className="escuelas"  style={{display:"flex",alignContent:"space-between"}}>
        {Usuario.destino==7?<>
          <button className="button-17"onClick={()=>{seteaEscuela("Escuela de Policia")}}>Escuela de Policía  </button>
  <button className="button-17" onClick={()=>{seteaEscuela("Escuela de Investigaciones")}}>Escuela de Investigaciones  </button>
  <button className="button-17" onClick={()=>{seteaEscuela("Escuela Superior")}}>Escuela Superior </button>
  <button className="button-17" onClick={()=>{seteaEscuela("Escuela de Especialidades")}}>Escuela de Especialidades  </button>
  <button className="button-17" onClick={()=>{seteaEscuela("Extencion Comunitaria")}}>Extensión Comunitaria  </button> 
  <button className="button-17" onClick={()=>{seteaEscuela("Educacion a Distancia")}}>Educación a Distancia </button> 
        
        </>:<>
        {Usuario.destino==10?(<button className="button-17" onClick={()=>{seteaEscuela("Extencion Comunitaria")}}>Extensión Comunitaria</button>):""}
        {Usuario.destino==15||Usuario.destino==19||Usuario.destino==28?(<button className="button-17" onClick={()=>{seteaEscuela("Escuela de Investigaciones")}}>Escuela de Investigaciones  </button>):""}
        {Usuario.destino==14||Usuario.destino==18||Usuario.destino==27?(<button className="button-17"onClick={()=>{seteaEscuela("Escuela de Especialidades")}}>Escuela de Especialidades</button>):""}
        {Usuario.destino==12||Usuario.destino==16||Usuario.destino==25?(<button className="button-17"onClick={()=>{seteaEscuela("Escuela de Policia")}}>Escuela de Policía  </button>):""}
        {Usuario.destino==11?(<button className="button-17"onClick={()=>{seteaEscuela("Educacion a Distancia")}}>Educación a Distancia  </button>):""}
        {Usuario.destino==13||Usuario.destino==17||Usuario.destino==26?(<button className="button-17"onClick={()=>{seteaEscuela("Escuela Superior")}}>Escuela Superior</button>):""}
        </>
        
        
        
        
        
        
        
        
        }
       

</div>
{escuelaSeleccionada?<>
<div>
  
  
 
  {mostrarCursos&&cursosSeteados.Cursos.length>0?

  ""
  :<strong>"No hay cursos disponibles"</strong>}
  </div>
 
<button className="btn btn-primary"onClick={crearCurso}>Crear Curso</button>
{mostrarCursos&&cursosSeteados.Cursos.length>0?<>

<button className="btn btn-secondary" onClick={crearMaterias}>Crear Materias</button><button className="btn btn-light">Asigna Masiva</button></>:""}





</>:""}
{mostrarEditaCurso?<>

<h1>Creando CURSOS</h1>
<FormEditaCurso Datos={escuelaSeleccionada} refresco={()=>seteaEscuela(escuelaSeleccionada)} cas={()=>seteaEscuela(escuelaSeleccionada)}/>
</>:""}
{mostrarEditaMateria?<>

<h1>Creando Materias</h1>
<FormEditaMateria Datos={escuelaSeleccionada} Cursos={cursosSeteados} refresco={()=>seteaEscuela(escuelaSeleccionada)} cas={()=>seteaEscuela(escuelaSeleccionada)}/>
</>:""}
{mostrarMateriasDevueltas?<><div style={{overflow:"auto",height:"350px",width:"800px"}}><DevuelveMaterias cosas={materiasDevueltas}  myID={(value)=>verificoMateria(value)}/></div></>:""}
</div>
<div>

<div>
      
        </div></div>
</div>
</div>
         
      </Modal>



        <div class="docentesConteiner">
           <h1 className="tituloDocentes">Menú de Gestión de Docentes y Horas Cátedras</h1> 
           
           <div className="btn-group">
           <button className="btn btn-warning"  onClick={volver}>Volver</button>
           </div>
           
           </div>
           <div class="botonesConteiner">

{Usuario.destino=="1"||Usuario.destino=="45"||Usuario.destino=="7"||Usuario.destino>="10"&&Usuario.destino<"20"||Usuario.destino=="25"||Usuario.destino=="26"||Usuario.destino=="27"||Usuario.destino=="28"?<><button className="button-18"  onClick={informaInasistencias}>Informe Inasistencias Docentes</button></>
:""}
{Usuario.destino=="7"?<><button className="button-18"  onClick={()=>setDocentesIsOpen(true)}>Gestión Docentes</button><button className="button-18" onClick={()=>setControlLectorIsOpen(true)}>Control Lector</button></>:""}
<button className="button-18" onClick={openModalInformeDocente}>Informe Horas Docentes</button>

<button className="button-18" onClick={()=>setHorasIsOpen(true)}>Gestión Horas catédras e Inasistencias</button>
        
</div>
<div class="usuarioConteiner">
  <div className="h3Conten">
<h3>Usuario logueado:{Usuario.nombre +" " +Usuario.apellido +" de "+devuelveDependencia().filter(x=>x.id==Usuario.destino)[0].descripcion}</h3>
</div>
</div>
        </>
    )
}
export default Docentes