import * as React from 'react';
import {useState} from 'react'
import '../cadetes/cosa.css';
import axios from 'axios';
import csvToJson from 'csvtojson'
import { Spinner } from 'reactstrap';
import swal from 'sweetalert'
import { CSVLink } from "react-csv";

function CargaMasiva(props) {
  function tieneTodosLosCampos(Listado, camposRequeridos) {
    if (!Array.isArray(Listado)) {
      throw new Error('El primer argumento debe ser un array');
    }
  
    if (!Array.isArray(camposRequeridos) || !camposRequeridos.every(field => typeof field === 'string')) {
      throw new Error('El segundo argumento debe ser un array de strings');
    }
    return Listado.every(item => {
      const itemFields = Object.keys(item);
      return (
        itemFields.length === camposRequeridos.length &&
        camposRequeridos.every(field => item.hasOwnProperty(field))
      );
    });
  }
  
 

  const camposObligatorios = [ 'docenteApellido', 'docenteNombres','docenteDni','docenteEstado'];
  const reducirArray = (array) => {
  
    const arrayReducido=array.map(objeto =>({
     
      docenteApellido: objeto.docenteApellido,
      docenteNombres: objeto.docenteNombres,
      docenteDni: objeto.docenteDni,
      docenteEstado:objeto.docenteEstado
    }))
    const encontrarUnicos = (array) => {
      // Convertir todos los docenteDni a cadenas de texto
      const arrayStringDni = array.map(objeto => ({
        ...objeto,
        docenteDni: String(objeto.docenteDni)
      }));
    
      // Crear un objeto para contar las apariciones de cada docenteDni
      const dniCount = arrayStringDni.reduce((acc, objeto) => {
        acc[objeto.docenteDni] = (acc[objeto.docenteDni] || 0) + 1;
        return acc;
      }, {});
    
      // Filtrar los objetos cuyo docenteDni aparece solo una vez
      return arrayStringDni.filter(objeto => dniCount[objeto.docenteDni] === 1);
    }


    return encontrarUnicos(arrayReducido);
  }

  const headers = [
    { label: "dniCertificado", key: "docenteApellido" },
    { label: "apellidoNombre", key: "docenteNombres" },
    { label: "sedeCertificado", key: "docenteCuil" },
    { label: "sedeCertificado", key: "docenteDni" },
    { label: "sedeCertificado", key: "docenteMail" },
    { label: "sedeCertificado", key: "docenteTelefono" },
    { label: "sedeCertificado", key: "docenteDomicilio" },
    { label: "sedeCertificado", key: "docenteEstado" },
    { label: "grado", key: "grado" }
   
   
  ];

  
  
  function comparaExistencias(listado1, listado2, campoValor) {
   
    const compararArrays = (array1, array2) => {
      const array1StringDni = array1.map(objeto => ({
        ...objeto,
        docenteDni: String(objeto.docenteDni)
      }));
    
      const array2StringDni = array2.map(objeto => ({
        ...objeto,
        docenteDni: String(objeto.docenteDni)
      }));
      const dniSet = new Set(array2StringDni.map(objeto => objeto.docenteDni));
      const noRepetidosArray1 = array1StringDni.filter(objeto => !dniSet.has(objeto.docenteDni));
      const dniSet1 = new Set(array1StringDni.map(objeto => objeto.docenteDni));
      
      return [...noRepetidosArray1];
    }
    
   
    
    const elementosNoRepetidos = compararArrays(listado1, listado2);
   return(elementosNoRepetidos);
    
  }
 

  const enviaLista=async(listado) => {
    
    const array1StringDni = listado.map(objeto => ({
      ...objeto,
      docenteDni: String(objeto.docenteDni)
    }));
   
    if(tieneTodosLosCampos(array1StringDni, camposObligatorios)){
      alert("Parece todo Ok en el listado")
     
        try {
          const client = axios.create({
            baseURL:process.env.REACT_APP_BASEURL+"docentes/devuelveDocentes"
            });
          const enviados=await client.post('/')
          if(enviados){
            console.log(enviados.data.Docente)
           props.myID("dale")
          const docentes=reducirArray(enviados.data.Docente)

    
  const listadosComparados = comparaExistencias(array1StringDni,docentes, 'dniDocente');
  console.log(listadosComparados)
alert(array1StringDni.length+" subidos en archivo "+ listadosComparados.length+" a incorporar")
envia(listadosComparados)
          }}
        catch (error) {}


    }
else{
  alert("archivo con campos incorrectos, por favor compruebe el nombre de las columnas y respetar los simbolos")
}
   }
   const envia=async(data)=>{
    try {
      const client = axios.create({
        baseURL:process.env.REACT_APP_BASEURL+"docentes/creaDocenteMasivo"
        });

      const enviados=await client.post('/',data)
      if(enviados){
       alert("Incorporacion finalizada con: "+enviados.data.Contador)
       props.myID("dale")
      }
    } catch (error) {
      
    }
   
          }
  const volver=()=>
  {
    
    props.myID("dale")
  }
  

   
  const [cargando, setCargando] = useState(false);
     const [file, setFile] = useState();
     const [cadetes, setCadetes] = useState();
     const fileReader = new FileReader();
  

     
     const subirArchivoCSV =(e)=>{
try{
  var reader=new FileReader();
  reader.readAsText(e.target.files[0]);
  reader.onload=()=>{

    const simbolo=","
    const simpolo2=";"
   
    csvToJson({
        
      checkType:true,
      delimiter:simpolo2
  })
  .fromString(reader.result)
  .subscribe((jsonObj)=>{
  
  }).then(f=>subirArchivo(f))


 }
   
  
  reader.onerror=error=>{
      console.log("Error: ",error)

  }
  
}catch{

}
      
    }
   
    const subirArchivo=(f)=>{

      console.log(f)

    }



  return (
<>
    <div className=''>

          <div className=' '>

  
<h1 >Modulo de Upload de archivos CSV</h1>
<br></br>

<form>
{cargando && (<Spinner color='priority'/>)}
{!cargando && <input className='btn btn-success'
    type={"file"}
   name='lista'
    id={"csvFileInput"}
    accept={".csv"}
   onChange={subirArchivoCSV}
  />}
  
  <CSVLink data={[]} filename={"Ejemplo Cursantes"} style={{marginTop:"4px",marginLeft:"4px"}}className="btn btn-success"separator={";"}
      target="_blank" headers={headers}> Archivo de ejemplo</CSVLink>

</form>

<br />



 <input style={{padding:"15px"}} type="button" value="Cancelar" className="btn btn-secondary" onClick={volver}/>
 </div>
        </div>
       


</>
  );
}
export default CargaMasiva;
