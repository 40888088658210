import axios from 'axios';
import Swal from 'sweetalert2'
import obtieneDatos  from '../tools/obtieneDatosCookiesUsuario'

const Usuario=obtieneDatos();

const anulaAsignacionDocente =async(datos)=>{
  const realizaCambios=async(viejaAnulada)=>{
    const fechaHoy=new Date()
        
            // Obtener el año, el mes y el día
            const year = fechaHoy.getFullYear();
            const month = String(fechaHoy.getMonth() + 1).padStart(2, '0'); // getMonth() devuelve el mes de 0 a 11
            const day = String(fechaHoy.getDate()).padStart(2, '0');
            
            // Formatear la fecha en YYYY-MM-DD
            const formattedDate = `${year}-${month}-${day}`;
  Swal.fire({
      title: "Indique en que fecha fue el cese del Cargo",
      icon: "question",
      showCancelButton: true,
      cancelButtonText:`Cancelar`,
      html: `
  <input
  type="date"
  value=${formattedDate}
  min=${viejaAnulada.fechaInicio}
  max=${formattedDate}
  class="swal2-input"
  id="range-value" >
  `}).then((result) => {
      if (result.isConfirmed) {
        
        continuo(document.getElementById('range-value').value)
      }
   else if(!result.isDismissed){
   
    continuo(formattedDate)
  
  }})
  const continuo=async(fecha)=>{
  

  const viejaAsignacionMateriaDatos={
  fechaFinalizacion:fecha,
  usuarioID:Usuario.id,
  sedeID:Usuario.sedeID,
  dependenciaID:Usuario.destino,
  estado:"Anulada"
  }


  const clienteAnulado = axios.create({
        
    baseURL:process.env.REACT_APP_BASEURL+"docentes/anulaAsignacion"
    });
   
    try {
  
      const anulacion=await clienteAnulado.post('/'+viejaAnulada.id,viejaAsignacionMateriaDatos)
    
    if(anulacion)
      {
        
  
          const clientLog = axios.create({
       
            baseURL:process.env.REACT_APP_BASEURL+"docentes/creaLogCambioDocente/"
            });

          const fechaHoy=new Date()
      
          // Obtener el año, el mes y el día
          const year = fechaHoy.getFullYear();
          const month = String(fechaHoy.getMonth() + 1).padStart(2, '0'); // getMonth() devuelve el mes de 0 a 11
          const day = String(fechaHoy.getDate()).padStart(2, '0');
          
          // Formatear la fecha en YYYY-MM-DD
          const formattedDate = `${year}-${month}-${day}`;
const log={
  fechaCambio:formattedDate,
  usuarioCambio:Usuario.id,
  docenteInicial:viejaAnulada.docenteDni,
  docenteFinal:-1,
  idAsignaInicial:viejaAnulada.id,
  idAsignaRemplazo:-1,
  fechaInicialMateria:viejaAnulada.fechaInicio,
  fechaFinalMateria:viejaAnulada.fechaFinalizacion

}


          const creaLog=await clientLog.post('/',log)

         if(creaLog){
           Swal.fire({
             text:"Anulacion del Cargo Docente",
             title:"Cambio realizado correctamente",
         buttons:"Aceptar",
         timer: "4000",
             icon:"success"})}
           

      }
    
    
    
    
    
    }catch{}


}
  
  
  
  
  
  
    
   }
  
  
  





    const client = axios.create({
    baseURL:process.env.REACT_APP_BASEURL+"docentes/devuelveIdAsignacion/"});
        try {
         const datosRecuperados= await client.post('/'+datos.materiaAsignadaId)

if(datosRecuperados){
const inforMateria=datosRecuperados.data.respuesta[0]
realizaCambios(inforMateria)
}
}
catch{}
      
}
export default anulaAsignacionDocente

