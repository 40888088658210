import React, { useState,useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {useNavigate, useLocation} from 'react-router-dom';
import Cookies from 'universal-cookie';
import Child from "../tools/Child.js";
import { Spinner } from 'reactstrap';
import { CSVLink } from "react-csv";
import ModuloDevulveExpediente from './ModuloDevulveExpediente.jsx';
import axios from 'axios';
import ObtieneDatosCookies from '../tools/obtieneDatosCookiesUsuario.js';
import convierteActualizaMovimiento from '../adaptadores/convierteActualizaMovimiento.js';
import FooterCorp from '../modulos/footerCorp.jsx';
import Modal from 'react-modal';
import FormNuevoExpte from './formNuevoExpte.js';
import FormEditaExpte from './formEditaExpte.js'
import ModalDevuelveListas from './ModalDevuelveListas.js'
import swal from 'sweetalert';
import seteaCokieReciboExpediente from './seteaCokieReciboExpediente.js'
import ModuloDevuelveListaMovExpedientes from './ModuloDevuelveListasMov.jsx';
import ModuloDevulveMovExpediente from './ModuloDevulveMovExpediente';
import devuelvoUsuario from './devuelveUsuario.js';
import devuelveSede from '../tools/devuelveSede.js';
import devuelveDependenciaConstultada from '../tools/devuelveDependencia.js';
import devuelveCategorias from '../modulos/devuelveCategorias.js';
import "./expedientes.css"
import Paginacion from './Paginacion.jsx';

import ModuloDevuelveListaExpedientesDependencia from './ModuloDevuelveListaExpedientesDependencia.jsx';


function ConsultaExpediente() {
const location= useLocation();

const queryParams = new URLSearchParams(location.search);

// Obtener el valor de un parámetro específico
const queryValue = queryParams.get('nroExpediente'); // Aquí 'query' es el nombre del parámetro

useEffect(() => {
  if(queryValue){
    const elemento={"expediente":queryValue}
   onSubmit(elemento)
  }
}, []);
  const dependencias= devuelveDependenciaConstultada();
dependencias.pop(x=>x.descripcion=="zDestino")//quito el destino de los pases

  const regresaBienFechaYDia=(dato)=>{
   
    if(dato){
        const [dia, mes, ano] = dato.split("-");
        const [a,b]=ano.split("T")
        const [x,y,z]=b.split(":")
    
        const mesReal=parseInt(mes)-1;
        const horaReal=parseInt(x)-3;
        const date4 = new Date(parseInt(dia), parseInt(mesReal),parseInt(a),parseInt(horaReal), parseInt(y)); 
        const horita=date4.toLocaleTimeString()
        const [hora,minutos,segundos]=horita.split(":")
    const fechita=date4.toLocaleDateString()
    
        let cambiada = fechita// + " a las "+hora+":"+minutos;
        return cambiada
    }else{
        return"-"
    }
   
}
const[modalNuevoOpen,setModalNuevoOpen]=useState(false)
const [ExpedienteSeteado, setExpedienteSeteado] = useState(null);
const [MovExpSeteado, setMovExpSeteado] = useState(null);
const [MovsExpSeteado, setMovsExpSeteado] = useState(null);
const navitate = useNavigate()
const usuario=ObtieneDatosCookies()
if(!usuario.id)
{alert("Acceso no Autorizado");window.location.href='./';}
const [division,setDivision]=useState(usuario.destino)
const [esAnexado,setEsAnexado]=useState(false)
const [veoSecretaria,setVeoSecretaria]=useState(usuario.destino)
const [veoEstadoExpediente,setVeoEstadoExpediente]=useState("")
const [LectorOn,setOn]=useState(false)
const [expediente,setNroExpediente]=useState(null)
const [listaDeExpedientes,setListaDeExpedientes]=useState([])
const [listaDeExpedientesDependencia,setListaDeExpedientesDependencia]=useState([])
const [listaCompletadaFinal,setlistaCompletadaFinal]=useState([])
const [cargando,setCargando]=useState(false)
const [listaFiltrada,setListaFiltrada]=useState("")
const [modalNuevoExpedienteIsOpen, setModalNuevoExpedienteIsOpen] = useState(false);
const [modalListaExpedienteDependenciaIsOpen, setModalListaExpedienteDependenciaIsOpen] = useState(false);
const [cantidad,setCantidad]=useState()
const [modalEditaExpedienteIsOpen, setModalEditaExpedienteIsOpen] = useState(false);
const [modalListaExpedienteIsOpen, setModalListaExpedienteIsOpen] = useState(false);
const [modalListaMovExpedienteIsOpen, setModalListaMovExpedienteIsOpen] = useState(false);
const [leyendaLector,setLeyenda]=useState("Activar lector QR")

  

const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm({});
  const headers = [
    { label: "Nro Expediente", key: "id" },
    { label: "Causante", key: "iniciador" },
    { label: "Categoria", key: "idCategoria" },
    { label: "Concepto", key: "concepto" },
    { label: "Fecha de Creacion", key: "createdAt" },
    { label: "Dependencia de Inicio", key: "dependenciaID" },
    { label: "Sede", key: "sedeID" },
    { label:"UltimoMovimiento",key:"ultimoMovimiento"},
    { label:"FechaUltimoMovimiento",key:"fechaUltimoMovimiento"}
   
  ];
  const customStyles = {
    content: {
  
    overflowY: "scroll",zIndex:"10"
 
    },
  };
const abrirModal=()=>{
  setModalNuevoOpen(true)
  setCargando(false)
}
  function openModalNuevoExpediente() {
    if(LectorOn){
      setOn(false)
      setLeyenda("Encender lector QR")
        }
    setModalNuevoExpedienteIsOpen(true);
  }

  function afterOpenModal() {
  
    
  }
  function cierraModal(){
setModalNuevoOpen(false)
  }
  function closeModalNuevoExpediente() {
    setModalNuevoExpedienteIsOpen(false);

  }
  function openModalEditaExpediente() {
    setModalEditaExpedienteIsOpen(true);
  }

  function closeModalEditaExpediente() {
    setModalEditaExpedienteIsOpen(false);
    onSubmit(expediente)


  }
  function closeModalListaExpedienteDependencia(valor) {

    setModalListaExpedienteDependenciaIsOpen(false);
try {
   if(valor){
      const elemento={"expediente":valor[0].id}
   onSubmit(elemento)}
} catch (error) {
  
}
   
  }
  function openModalListaExpedienteDependencia() {
    setCargando(false)
    if(LectorOn){
      setOn(false)
      setLeyenda("Encender lector QR")
        }
    setModalListaExpedienteDependenciaIsOpen(true);
  }
  function openModalListaExpediente() {

    if(LectorOn){
      setOn(false)
      setLeyenda("Encender lector QR")
        }
    setModalListaExpedienteIsOpen(true);
    setCargando(false)
   // setPrimerIngreso(true)
  }
  function openModalListaMovExpediente() {
    setModalListaMovExpedienteIsOpen(true);
  }
  function closeModalListaMovExpediente() {

    setModalListaMovExpedienteIsOpen(false);
  }

  const devuelveTodosExpedientesDependencia=async()=>{
    var listaFull=[]
    
        
            setCargando(true)
            const client2 = axios.create({
              baseURL:process.env.REACT_APP_BASEURL+"expedientes/consultaEnDependencia"
              });
            try {
            
              
            
                listaFull=await client2.post("/"+usuario.destino)
                
              
              
            if(listaFull){
              var listado=[]

          
          
        
        for (let index = 0; index < listaFull.data.Lista.length; index++) 
        {
          if(listaFull.data.Lista[index].Movimientos.length>0)
          {
           
            const ultimoMov=listaFull.data.Lista[index].Movimientos.pop()

            if(ultimoMov.destinoEntradaID==usuario.destino&&ultimoMov.sedeEntradaID==usuario.sedeID)
            {
              listaFull.data.Lista[index].Expediente.ProvinoDesdeDestino=ultimoMov.destinoSalidaID
              listaFull.data.Lista[index].Expediente.ProvinoDesdeSede=ultimoMov.sedeSalidaID
              listaFull.data.Lista[index].Expediente.FechaUltimoMovimiento=regresaBienFechaYDia(ultimoMov.fechaEntrada)
              listado.push(listaFull.data.Lista[index].Expediente)
            }

           
        } }
        openModalListaExpedienteDependencia(true)
         setListaDeExpedientesDependencia(listado.reverse())

    setCargando(false)
      }
            }
            catch (error) {}
       
      }

 

const devuelveExpedienteAPI=async(dato) => {
try{
  const client = axios.create({
    baseURL: process.env.REACT_APP_BASEURL+"expedientes/"
    });
    const response = await client.post('/'+dato);
    setMovExpSeteado(null)
if(response.data.Expediente){

  const expediente=response.data
  const cosa=structuredClone(expediente)

  
  if (expediente.Expediente.idExptPosterior>0){
    setEsAnexado(true)
  }else{
    setEsAnexado(false)
  }
  if(cosa.MovActuales!=""){

    setMovsExpSeteado(cosa.MovOriginales)
    setMovExpSeteado(expediente.MovActuales.pop())
  
  }
   
  else{
   
    if(expediente.Expediente&&expediente.MovOriginales!=""){
      setMovsExpSeteado(cosa.MovOriginales)
      setMovExpSeteado(expediente.MovOriginales.pop())}
  
  
  
 

 
}return response.data
}else{
  return response.data
}

   
}catch{}
 
    }
  
   
      
    const ActualizaMovimientoExpedienteAPI=async(direccion,data) => {

      const client = axios.create({
      baseURL: process.env.REACT_APP_BASEURL+"movimientos/actualiza"
      });
      try {
        const response = await client.put('/'+direccion,data).then(f=>generoRecibo(f));

        onSubmit(expediente)}
      catch (error) {}}

  const onSubmit = (parametroExpediente) => {
   
   
    setNroExpediente(parametroExpediente)
setModalNuevoOpen(false)
  f(parametroExpediente.expediente).then(x => setExpedienteSeteado(x));

    
  }
 
  const f = async(a) => {
    
  return await devuelveExpedienteAPI(a)}

const recibirExpediente=async()=>
{

  
  swal({
    title: "¿Quiere registrar algún comentario u observacion al recibir el Expediente?",
    content: "input", closeOnClickOutside: false,  buttons: {
      cancel: "No",
      confirm: "Si"}
  }).then(respuesta=> ActualizaMovimientoExpedienteAPI(MovExpSeteado.id,convierteActualizaMovimiento(respuesta)));               

  //
}
const generoRecibo= async(info)=>{
  
  const usuarioRecibe= await devuelvoUsuario(info.data.info.usuarioEntradaID)
  swal({
    title: "¿Desea imprimir recibo?",
    closeOnClickOutside: false,  buttons: ["No","Si"]
  }).then(respuesta=> {if(respuesta){

   if(usuarioRecibe){
    const datosImportantes={

      usuarioEntradaID:info.data.info.usuarioEntradaID,
      destinoEntradaID:info.data.info.destinoEntradaID,
      destinoSalidaID:info.data.info.destinoSalidaID,
      sedeSalidaID:info.data.info.sedeSalidaID,
      sedeEntradaID:info.data.info.sedeEntradaID,
      expedienteID:info.data.info.expedienteID,
      fechaEntrada:info.data.info.fechaEntrada,
      comentario:info.data.info.comentario,
      fojasActuales:info.data.info.fojasActuales,
      idRecibo:"00-"+info.data.info.id,
      usuarioRecibe:usuarioRecibe
     }
     seteaCokieReciboExpediente(datosImportantes)
     window.open('/imprimeRecibo')}
   }
    })            

  
}
  
const paraDondeSaco=()=>{
  swal({
    title: "¿Sale de la orbita del ISeP?",
    closeOnClickOutside: false,  buttons: {
      cancel: "No",
      confirm: "Si, sale"}
  }).then(respuesta=> respuesta?dondeSale():dondeSaleDentroISeP())
  
  const dondeSaleDentroISeP=()=>{
    swal({
      title: "¿Indique a que destino dentro del ISeP enviara este expediente?",
      content: "input", closeOnClickOutside: false,  buttons: {
      
        confirm: "Aceptar"}
    }).then(respuesta=> cantidadFojas(respuesta,2))  
  }
  const dondeSale=()=>
  {
    swal({
      title: "¿A que dependencia sale fuera del ISeP?",
      content: "input", closeOnClickOutside: false,  buttons: {
      
        confirm: "Aceptar"}
    }).then(respuesta=> cantidadFojas(respuesta,1))  
  }

  const cantidadFojas=(sale,respuesta)=>{

    swal({
      title: "¿Cantidad de Fojas?",
      content: "input", closeOnClickOutside: false,  buttons: {
        confirm: "Confirmar"}
    }).then(respuesta2=>respuesta===2?creoMovimiento(sale,respuesta2):creoMovimientoAfuera(sale,respuesta2))
    
    const creoMovimiento=async(comentarioSalida,fojas)=>{
      const fecha = new Date();
      const movimiento= {
              expedienteID:ExpedienteSeteado.Expediente.id,
              usuarioSalidaID:usuario.id,
              destinoSalidaID:usuario.destino,
              sedeSalidaID:usuario.sedeID,
              fechaSalida:fecha,
              comentarioSalida:comentarioSalida,
              fojasActuales:fojas
              
      }

      const client = axios.create({
        baseURL: process.env.REACT_APP_BASEURL+"movimientos/crea"
        });
        
        try {
    
    
    
       const response = await client.post('/',movimiento);
         if(response){
          onSubmit(expediente)}
         }
        catch (error) {alert("Algo Fallo en la transaccion avisale a DTyD")}


    }
    const creoMovimientoAfuera=async(comentarioSalida,fojas)=>{
      const fecha = new Date();
      const movimiento= {
              expedienteID:ExpedienteSeteado.Expediente.id,
              usuarioSalidaID:usuario.id,
              destinoSalidaID:usuario.destino,
              sedeSalidaID:usuario.sedeID,
              fechaSalida:fecha,
              comentarioSalida:comentarioSalida,
              fojasActuales:fojas,
              esArchivo:comentarioSalida,
    }
    
    const client = axios.create({
      baseURL: process.env.REACT_APP_BASEURL+"movimientos/crea"
      });
      
      try {
  
  
  
     const response = await client.post('/',movimiento);
       if(response){
        onSubmit(expediente)}
       }
      catch (error) {alert("Algo Fallo en la transaccion avisale a DTyD")}
  
  
  }
  }
}
const sacoCero=()=>{
if(usuario.destino=="6"||usuario.destino=="7"||usuario.destino=="8"||usuario.destino=="21"){
 paraDondeSaco()
}
   else{
    swal({
      title: "¿Cantidad de Fojas?",
      content: "input", closeOnClickOutside: false,  buttons: {
       
        confirm: "Confirmar"}
    }).then(respuesta=> preguntoDondeVa(respuesta)) 

const preguntoDondeVa=(fojas)=>{
  swal({
    title: "Indique a que destino dentro del ISeP enviara este expediente",
    content: "input", closeOnClickOutside: false,  buttons: {
     
      confirm: "Confirmar"}
  }).then(respuesta=> finalizaTransaccion(fojas,respuesta))
 
 
 
 
}

const finalizaTransaccion=async(fojas,motivo)=>{
  const fecha = new Date();
  const movimiento={
  
      expedienteID:ExpedienteSeteado.Expediente.id,
      usuarioSalidaID:usuario.id,
      destinoSalidaID:usuario.destino,
      sedeSalidaID:usuario.sedeID,
      fechaSalida:fecha,
      comentarioSalida:motivo,
      fojasActuales:fojas,      

  }
  
  const client = axios.create({
    baseURL: process.env.REACT_APP_BASEURL+"movimientos/crea"
    });
    
    try {



   const response = await client.post('/',movimiento);
     if(response){
      onSubmit(expediente)}
     }
    catch (error) {alert("Algo Fallo en la transaccion avisale a DTyD")}
}

   }

}


const volver=()=>{navitate('/usuarioLogueado')}


const ActivaLector=()=>{
  if(LectorOn){
setOn(false)
setLeyenda("Encender lector QR")
  }else{
setOn(true)
setLeyenda("Apagar lector QR")
  }
}
const capturoQR=(valor)=>{
const elemento={"expediente":valor}

onSubmit(elemento)

ActivaLector()

}

const EditaExpediente=(valor)=>{

setModalEditaExpedienteIsOpen(true)

}
function closeModalListaExpediente(valor) {

  if(valor.id>0){
    const elemento={"expediente":valor.id}
 onSubmit(elemento)

 setModalListaExpedienteIsOpen(false);
 setListaFiltrada("")
  }
     
  else{ setModalListaExpedienteIsOpen(false);}
  
  
    }
const seteaListaCompletadaFinal=()=>{
  const devuelveDependenciaIniciadora=(valor)=>{
const definicion=dependencias.filter(x=>x.id==valor)[0].descripcion

     return definicion
  }
  var listaCompletada=[]  
  
  for (let index = 0; index < listaDeExpedientes.length; index++) {
    const dato={
     "id":listaDeExpedientes[index].id,
     "sedeID":devuelveSede().filter(x=>x.id==listaDeExpedientes[index].sedeID)[0].descripcion,
     "concepto":listaDeExpedientes[index].concepto
    , "dependenciaID":devuelveDependenciaIniciadora(listaDeExpedientes[index].dependenciaID),
    "iniciador":listaDeExpedientes[index].iniciador,
    "createdAt":listaDeExpedientes[index].createdAt,
    "idCategoria":devuelveCategorias().filter(x=>x.id==listaDeExpedientes[index].idCategoria)[0].descripcion
    ,"ultimoMovimiento":listaDeExpedientes[index].UltimoMovimiento,
    "fechaUltimoMovimiento":listaDeExpedientes[index].FechaUltimoMovimiento
  
  }
    listaCompletada.push(dato)
    
 
}
  setlistaCompletadaFinal(listaCompletada)
  
}
const ordenaLista=async(valor,como)=>{

  var listaFull=[]

var estado=""
    if(como=="all"){
      estado=null
    }else{
      estado=como
    }

  setCargando(true)

  const client2 = axios.create({
    baseURL:process.env.REACT_APP_BASEURL+"expedientes/consultaTodos/"
    });
  try {
  

   if(usuario.destino==7||usuario.destino==1)
   {
    listaFull=await client2.post("/"+valor,estado!=""?{"estado":estado}:"")
   }else
   {
    listaFull=await client2.post("/"+valor,estado!=""?{"estado":estado}:"")
   }
    
  
    
      
  if(listaFull){

    var listado=[]





for (let index = 0; index < listaFull.data.Lista.length; index++) {
  if(listaFull.data.Lista[index].Movimientos.length>0){
    const ultimoMov=listaFull.data.Lista[index].Movimientos.pop()
    if(ultimoMov.destinoEntradaID){
      listaFull.data.Lista[index].Expediente.UltimoMovimiento=dependencias.filter(x=>x.id==ultimoMov.destinoEntradaID)[0].descripcion
      listaFull.data.Lista[index].Expediente.FechaUltimoMovimiento=regresaBienFechaYDia(ultimoMov.fechaEntrada)
    }else{
      if(ultimoMov.esArchivo){
       
        listaFull.data.Lista[index].Expediente.UltimoMovimiento=ultimoMov.esArchivo
        listaFull.data.Lista[index].Expediente.FechaUltimoMovimiento=regresaBienFechaYDia(ultimoMov.fechaEntrada)
      }else{
      listaFull.data.Lista[index].Expediente.UltimoMovimiento="En TRANSITO"}
      listaFull.data.Lista[index].Expediente.FechaUltimoMovimiento=regresaBienFechaYDia(ultimoMov.fechaSalida)
    }
   
  }else{
    listaFull.data.Lista[index].Expediente.UltimoMovimiento="Sin Movimientos"
    listaFull.data.Lista[index].Expediente.FechaUltimoMovimiento="-"
  }
     listado.push(listaFull.data.Lista[index].Expediente)
}

  setListaDeExpedientes(listado.reverse())
  
  openModalListaExpediente(true)

  }
    }
  catch (error) {}
   
}
const seteoQueSecretariaVeo=(dato)=>{
  
  if(dato==veoSecretaria){
   
   
  }else{
    setVeoSecretaria(dato)
    ordenaLista(dato,veoEstadoExpediente)
  }
  


  //  


}
const seteoQueEstadoVeo=(estado)=>{
  if(estado==veoEstadoExpediente){
   
   
  }else{
    setVeoEstadoExpediente(estado)
    ordenaLista(veoSecretaria,estado)
  }


}
const anulaRegistro =async(data)=>{


  const confirmDelete = window.confirm(`¿Estás seguro de que deseas anular el expediente? ¡¡Esta acción no puede deshacerla!!`);
if(confirmDelete){
  const client2= axios.create({
    baseURL: process.env.REACT_APP_BASEURL+"expedientes/actualiza/"
    });
  try {


 const response2 = await client2.put('/'+data.expediente,{"estadoExpediente":"Anulado"}) 
 if(response2){
  alert("Expediente Anulado")
  onSubmit(data)
 }
    }catch{

    }}
}

const archivoExpediente=async()=>{
  swal({
    title: "¿Realmente quiere pasar a Archivo este Expediente?",
    closeOnClickOutside: false,  buttons: {
    cancel:"No",
      confirm: "Si, quiero Archivar"}
  }).then(respuesta=> respuesta?preguntoFojas():"") 


const preguntoMotivo=(respuesta)=>{
if(respuesta){
  
  const por=()=>{
    var fojas=respuesta
    swal({
      title: "Motivo de Archivo?",
      content: "input", closeOnClickOutside: false,  buttons: {
        
        confirm: "Continuar"}
    }).then(respuestaMovito=> continua(fojas,respuestaMovito))  
   
   
  }
  por()
  }else{
   preguntoFojas()
  }
}


const preguntoFojas=()=>{

  swal({
    title: "¿Cantidad de Fojas con la que se archiva?",
    content: "input", closeOnClickOutside: false,  buttons: {
      
      confirm: "Continuar"}
  }).then(respuesta=> preguntoMotivo(respuesta))  
 
 
}

const continua=(fojas, respuestaMovito)=>{
if(respuestaMovito){
ahoraSiAcrhivo(fojas,respuestaMovito)

}else{
alert("Debe especificar el motivo!")
}
}

}
const ahoraSiAcrhivo=async(fojas,motivo)=>{
  const fecha = new Date();
  const movimiento={
  
      expedienteID:ExpedienteSeteado.Expediente.id,
      usuarioSalidaID:usuario.id,
      destinoSalidaID:usuario.destino,
      sedeSalidaID:usuario.sedeID,
      
      sedeEntradaID:usuario.sedeID,
      fechaSalida:fecha,
      comentarioSalida:motivo,
      fojasActuales:fojas,
      esArchivo:"PASO A ARCHIVO",
      

  }
  
  const client = axios.create({
    baseURL: process.env.REACT_APP_BASEURL+"movimientos/crea"
    });
    const client2= axios.create({
      baseURL: process.env.REACT_APP_BASEURL+"expedientes/actualiza/"
      });
    try {



   const response = await client.post('/',movimiento);
   const response2 = await client2.put('/'+ExpedienteSeteado.Expediente.id,{"estadoExpediente":"Archivo"});
     if(response&&response2){
      onSubmit(expediente)}
     }
    catch (error) {}
}


const restaurarExpediente=()=>{
  swal({
    title: "¿Quiere registrar algún comentario u observacion al restaurar el Expediente?",
    content: "input", closeOnClickOutside: false,  buttons: {
      cancel: "No",
      confirm: "Si"}
  }).then(respuesta=> creoTransaccion(respuesta));               
const creoTransaccion=async(respuesta)=>{

  const fecha = new Date();
  const movimiento={
  
     
      usuarioEntradaID:usuario.id,
      destinoEntradaID:usuario.destino,
      sedeEntradaID:usuario.sedeID,
      fechaSalida:fecha,
      comentario:respuesta,
      esArchivo:null,
   }

   const client = axios.create({
    baseURL: process.env.REACT_APP_BASEURL+"movimientos/actualiza"
    });
    const client2= axios.create({
      baseURL: process.env.REACT_APP_BASEURL+"expedientes/actualiza/"
      });
    try {

   const response = await client.put('/'+MovExpSeteado.id,movimiento);
   const response2 = await client2.put('/'+ExpedienteSeteado.Expediente.id,{"estadoExpediente":"En el ISeP"});
     if(response&&response2){
      onSubmit(expediente)}
     }
    catch (error) {}

}


  //
}
const funcionNueva=async()=>{
  
 
setCargando(true)
  
    abrirModal()
    

}
  return (
    <div >

     <Modal
        isOpen={modalNuevoExpedienteIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalNuevoExpediente}
        style={customStyles}
        ariaHideApp={false}
      >

        
       <div className=" btn-group"> <button className="btn btn-danger" style={{display:"flex",alignContent:"flex-end"}}onClick={closeModalNuevoExpediente}>x </button> <a style={{alignSelf:"center"}}><strong>Creacion de Expediente: </strong></a>
      </div>
      <FormNuevoExpte myID={(value2)=>closeModalNuevoExpediente()} />
        
         
      </Modal>

      <Modal
        isOpen={modalListaExpedienteDependenciaIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalListaExpedienteDependencia}
        style={customStyles}
        ariaHideApp={false}
      >
       <div className=" btn-group"> <button className="btn btn-danger" style={{display:"flex",alignContent:"flex-end"}}onClick={closeModalListaExpedienteDependencia}>x </button><a style={{alignSelf:"center"}} ><strong>Expedientes en dependencia: </strong></a>
      
      </div>

     {listaDeExpedientesDependencia?<ModuloDevuelveListaExpedientesDependencia cosas={listaDeExpedientesDependencia} myID={(value2)=>closeModalListaExpedienteDependencia(value2)} />:""} 
        
         
      </Modal>


      <Modal
        isOpen={modalListaExpedienteIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalListaExpediente}
        style={customStyles}
        ariaHideApp={false}
      >
   
     

       <div className=" btn-group"> <button className="btn btn-danger" style={{display:"flex",alignContent:"flex-end"}}onClick={closeModalListaExpediente}>x </button> <a style={{alignSelf:"center"}} ><strong>Consulta Lista de Expediente: </strong></a>
       <input onChange={e => setListaFiltrada(e.target.value.toLocaleLowerCase())} type="text" placeholder='Ingrese el Iniciador' style={{height:"30px", marginLeft:"5px",marginTop:"3px"}}/>

     
       <div > 
       {cargando?<div style={{position:"absolute", width:"500px",fontSize:"20px", color:"#111111", backgroundColor:"#eeeeee"}}><Spinner color='danger'/>Accediendo...</div>:""}
        {usuario.destino==1||usuario.destino==7?<>

       <select value={veoSecretaria} id='selectorEscuela' onChange={(e)=>seteoQueSecretariaVeo(e.target.value)} style={{padding:"2px", marginLeft:"5px"}}>
       <option value="all">Todos</option>
       {dependencias.map((cate) =>
            <><option key={cate.id} value={cate.id}>
            {cate.descripcion}
            </option></>)}
        </select>
        
        
        
        
        </>:""
        
        
        
        }
       <select id='estado'value={veoEstadoExpediente} onChange={(e)=>seteoQueEstadoVeo(e.target.value)} style={{padding:"2px", marginLeft:"5px"}}>
        <option value="En el ISeP">En el ISeP</option>
       <option value="">Todos</option>
        <option value="Fuera del ISeP">Fuera del ISeP</option>
        <option value="Archivo">Archivo</option>
        </select>
        
        <CSVLink data={listaCompletadaFinal} onClick={()=>{seteaListaCompletadaFinal()}} filename={"Expedientes"} style={{marginTop:"4px",marginLeft:"4px"}}className="btn btn-success"separator={";"}
      target="_blank" headers={headers}> Descargar</CSVLink></div>
      </div>
{
  usuario.destino!=1&&usuario.destino!=25&&usuario.destino!=26&&usuario.destino!=7&&usuario.destino!=27&&usuario.destino!=28?  <ModalDevuelveListas listaExpedientes={listaDeExpedientes.filter((cosas)=>cosas.iniciador.toLowerCase().startsWith(listaFiltrada))} myID={(value2)=>closeModalListaExpediente(value2)} />: usuario.destino!=25&&usuario.destino!=26&&usuario.destino!=7&&usuario.destino!=27&&usuario.destino!=28? <> <ModalDevuelveListas  listaExpedientes={listaDeExpedientes.filter((cosas)=>cosas.iniciador.toLowerCase().startsWith(listaFiltrada)&&cosas.dependenciaID>=0)} myID={(value2)=>closeModalListaExpediente(value2)} /></>:""
}
{
  usuario.destino==25?   <ModalDevuelveListas  listaExpedientes={listaDeExpedientes.filter((cosas)=>cosas.iniciador.toLowerCase().startsWith(listaFiltrada)&&cosas.dependenciaID==2)} myID={(value2)=>closeModalListaExpediente(value2)} />: ""}
  {  usuario.destino==26? <ModalDevuelveListas  listaExpedientes={listaDeExpedientes.filter((cosas)=>cosas.iniciador.toLowerCase().startsWith(listaFiltrada)&&cosas.dependenciaID==3)} myID={(value2)=>closeModalListaExpediente(value2)} />:""
}
{  usuario.destino==27? <ModalDevuelveListas  listaExpedientes={listaDeExpedientes.filter((cosas)=>cosas.iniciador.toLowerCase().startsWith(listaFiltrada)&&cosas.dependenciaID==4)} myID={(value2)=>closeModalListaExpediente(value2)} />:""
}
{  usuario.destino==28? <ModalDevuelveListas  listaExpedientes={listaDeExpedientes.filter((cosas)=>cosas.iniciador.toLowerCase().startsWith(listaFiltrada)&&cosas.dependenciaID==5)} myID={(value2)=>closeModalListaExpediente(value2)} />:""
}
{  usuario.destino==7? <ModalDevuelveListas  listaExpedientes={listaDeExpedientes.filter((cosas)=>cosas.iniciador.toLowerCase().startsWith(listaFiltrada)&&cosas.dependenciaID>0)} myID={(value2)=>closeModalListaExpediente(value2)} />:""
}    
         
      </Modal>

      <Modal
        isOpen={modalListaMovExpedienteIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalListaMovExpediente}
        style={customStyles}
        ariaHideApp={false}
      >
       <div className=" btn-group"> <button className="btn btn-danger" style={{display:"flex",alignContent:"flex-end"}}onClick={closeModalListaMovExpediente}>x </button> <a style={{alignSelf:"center"}} ><strong>Consulta Lista de Expediente: </strong></a>
      
      </div>

     {ExpedienteSeteado?<ModuloDevuelveListaMovExpedientes listaMovimientos={MovsExpSeteado} myID={(value2)=>closeModalListaMovExpediente(value2)} />:""} 
        
         
      </Modal>
      <Modal
        isOpen={modalEditaExpedienteIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalEditaExpediente}
        style={{display:"flex",alignContent:"flex-end",zIndex:"10"}}
        ariaHideApp={false}
      >
       <div className=" btn-group"> <button className="btn btn-danger" style={{display:"flex",alignContent:"flex-end",zIndex:"10"}}onClick={closeModalEditaExpediente}>x </button> <a style={{alignSelf:"center"}}><strong>Edicion de Expediente: </strong></a>
      </div>
      <FormEditaExpte expedienteAEditar={ExpedienteSeteado?ExpedienteSeteado.Expediente:""} myID={(value2)=>closeModalEditaExpediente()} />
        
         
      </Modal>
     
<div class="contenedor_exp" style={{placeSelf:"center"}}>
    <h1 >Consultas de Expedientes ISeP</h1>
    <div className=''>

    <div className="input-group-text" class="botones_exp" style={{"text-align":"center"}}>
      
      {//ZONA DE HABILITACION DE BOTON CREAR EXPEDIENTE
      }
          <button value="Nuevo Expediente"class="btn btn-success" onClick={openModalNuevoExpediente}>Nuevo Expediente</button>
          {<><input type="button"  value={leyendaLector} className="btn btn-primary" onClick={ActivaLector} style={{margin:"5px"}}/></>}
          {<><input type="button"  value="En Dependencia" style={{margin:"5px"}}className="btn btn-dark" onClick={devuelveTodosExpedientesDependencia}/></>}
          <button className="btn btn-danger" onClick={funcionNueva}>Ver Todos</button>
          <button type="button"  value="Volver" className="btn btn-warning" onClick={volver}>Volver</button>
         
     <Modal
        isOpen={modalNuevoOpen}
        onRequestClose={cierraModal}
        style={{display:"flex",alignContent:"flex-end",zIndex:"10"}}
        ariaHideApp={false}
      >
       <div className=" btn-group"> <button className="btn btn-danger" onClick={()=>cierraModal()}>x </button> <a style={{alignSelf:"center"}}><strong>Listados: </strong></a>
      </div>

      <Paginacion cantidadPasada={cantidad} division={veoSecretaria}seteoSecretaria={(x)=>setVeoSecretaria(x)} myID={(x)=>onSubmit(x)} seteaCantidad={(xs)=>setCantidad(xs)}/>
         
      </Modal>
          {cargando?<div style={{position:"absolute", width:"500px",fontSize:"20px", color:"#111111", backgroundColor:"#eeeeee"}}><Spinner color='danger'/>Accediendo...</div>:""}


    </div>
   
    </div>
   


     

          
      
      {LectorOn&&<><div style={{height:"350px", marginTop:"5px"}}> 
      <Child direccion={(value) => capturoQR(value)} /></div></>}
     

    
        <form onSubmit={handleSubmit(onSubmit)} className='form_edit_back'>
        <section >
        

         
         
        <div class='input-group-text div_expe' >
       
          <div class="form-floating mb-3 ml-3" style={{width:"300px",zIndex:"-0"}}>
         
          <input type="number" class="form-control" id="floatingIniciador" placeholder="floatingIniciador" 
          {...register('expediente', {
          required: true,
          })}/>
          <label for="floatingIniciador">Ingrese el nro de Expediente</label>
        
          </div>
          <button onClick={onsubmit} style={{width:"120px"}}  className="btn btn-outline-success">Consulta</button>
         
         </div>
        
        
       

     
       
        </section>
      </form>
     {ExpedienteSeteado!==null?
      <div style={{border:"solid  1px  grey", paddingBlock:"10px", paddingInline :"10px"}}>
      <ModuloDevulveExpediente infoExpediente={ExpedienteSeteado}myID={(value2)=>EditaExpediente(value2)} />

      
{ExpedienteSeteado!==null&&MovExpSeteado!==null?<ModuloDevulveMovExpediente datos={MovExpSeteado}/>:ExpedienteSeteado?<><h4 style={{textAlign:"center"}}>"Sin Movimientos Registrados!"</h4></>:""}


{
/////////////SECRETARIA GENERAL y LOGISTICA RECIBEN DE AFUERA
usuario.destino==6||usuario.destino==8?!ExpedienteSeteado?"":(!esAnexado&&MovExpSeteado&&!MovExpSeteado.destinoEntradaID&&MovExpSeteado.esArchivo!="PASO A ARCHIVO"&&(<button  className="btn btn-success"onClick={recibirExpediente}>Recibir Expediente</button>)):""

}
<div class='div_sacar'>
{

!ExpedienteSeteado?"":(!esAnexado&&MovExpSeteado&&!MovExpSeteado.destinoEntradaID&&MovExpSeteado.esArchivo=="PASO A ARCHIVO"&&(<button  className="btn btn-success"style={{color:"yellow"}} onClick={restaurarExpediente}>Restaurar Expediente</button>))

}
{usuario.destino!=6?!ExpedienteSeteado?"":(!esAnexado&&MovExpSeteado&&!MovExpSeteado.destinoEntradaID&&MovExpSeteado.esArchivo==null&&usuario.nivel<5&&(MovExpSeteado.comentario?MovExpSeteado.comentario.slice(0,2)=="AD"?"":<button  className="btn btn-success"onClick={recibirExpediente}>Recibir Expediente</button>:<button  className="btn btn-success"onClick={recibirExpediente}>Recibir Expediente</button>)):""}

{!ExpedienteSeteado?"":(!esAnexado&&MovExpSeteado&&MovExpSeteado.destinoEntradaID==usuario.destino?(<><button className="btn btn-warning" onClick={sacoCero}>Sacar Expediente</button>
{ExpedienteSeteado.Expediente.estadoExpediente!=="Archivo"?<button  style={{"border":"2px solid black","color":"yellow"}}className="btn btn-danger" onClick={archivoExpediente}>Archivar Expediente</button>:""}

</>):"")}
{!ExpedienteSeteado?"":!esAnexado&&!MovExpSeteado&&ExpedienteSeteado.Expediente.dependenciaID==usuario.destino?<><button  className="btn btn-danger" onClick={sacoCero}>Sacar Expediente</button>

{ExpedienteSeteado.Expediente.estadoExpediente!=="Archivo"?<button  style={{"border":"2px solid black","color":"yellow"}} className="btn btn-danger" onClick={archivoExpediente}>Archivar Expediente</button>:""}
</>:""}

{usuario.destino==ExpedienteSeteado.Expediente.dependenciaID&&ExpedienteSeteado.Expediente&&!MovExpSeteado||usuario.destino=="7"&&ExpedienteSeteado.Expediente&&!MovExpSeteado?<button  className="btn btn-dark" onClick={()=>anulaRegistro(expediente)}>ANULAR EXPEDIENTE</button>:""}
{!ExpedienteSeteado?"":(!MovExpSeteado?"":MovExpSeteado&&usuario.nivel<4?(<button className="btn btn-secondary" onClick={openModalListaMovExpediente}>Ver Todos los Movimientos</button>):"")}
</div>
</div>:""}
   
<div style={{textAlign:"center"}}> <FooterCorp/></div>
    
</div>


      </div> 
      
   
  );
}
export default ConsultaExpediente;
